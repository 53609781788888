.password-input {
  position: relative;
  display: inline-block;
}

.password-field {
  padding-right: 30px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
}
